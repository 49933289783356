<template>
  <div class="sf-header" :class="{ 'checkout-header': isCheckoutPage }">
    <div class="container">
      <header ref="header" class="sf-header__header">
        <div class="title smartphone-only">
          {{
            $gt(
              'Welcome to Colfert Italia | Only for professionals with VAT number'
            )
          }}
        </div>
        <div class="back" v-if="isCheckoutPage">
          <SfLink :link="localePath('/')">
            <BackCheckoutIcon />
          </SfLink>
        </div>
        <slot name="logo" v-bind="{ logo, title }">
          <SfLink :link="localePath('/')">
            <SfImage
              v-if="logo"
              :src="logo"
              :alt="title"
              :width="logoWidth"
              :height="logoHeight"
              :placeholder="logo"
              class="sf-header__logo"
            />
            <p v-else class="sf-header__title">{{ title }}</p>
          </SfLink>
        </slot>
        <div class="sf-header__aside" v-if="!isCheckoutPage">
          <slot name="aside" />
        </div>
        <div class="heder-right">
          <div class="heder-right__title">
            {{
              $gt(
                'Welcome to Colfert Italia | Only for professionals with VAT number'
              )
            }}
          </div>
          <div class="menu_icon" v-if="!isCheckoutPage">
            <HamburgerIcon @click="openFilters" class="menu-open-icon" />
          </div>
          <div class="sf-header__actions" v-if="!isCheckoutPage">
            <slot name="search" v-bind="{ searchValue, searchPlaceholder }">
              <SfSearchBar
                :value="searchValue"
                :placeholder="searchPlaceholder"
                aria-label="Search"
                class="sf-header__search"
                @input="$emit('change:search', $event)"
                @keyup.enter="$emit('enter:search', $event)"
              />
            </slot>
            <slot
              name="header-icons"
              v-bind="{
                activeIcon,
                cartHasProducts,
                cartItemsQty,
                cartIcon,
                wishlistIcon,
                accountIcon,
              }"
            >
              <div class="sf-header__icons">
                <SfButton
                  :class="{ 'display-none': !accountIcon }"
                  class="sf-button--pure sf-header__action"
                  data-testid="accountIcon"
                  aria-label="Account"
                  @click="$emit('click:account')"
                >
                  <SfIcon
                    :icon="accountIcon"
                    size="1.25rem"
                    :class="{
                      'sf-header__icon is-active': activeIcon === 'account',
                    }"
                  />
                </SfButton>
                <SfButton
                  :class="{ 'display-none': !wishlistIcon }"
                  class="sf-button--pure sf-header__action"
                  data-testid="wishlistIcon"
                  aria-label="Wishlist"
                  @click="$emit('click:wishlist')"
                >
                  <SfIcon
                    class="sf-header__icon"
                    :icon="wishlistIcon"
                    size="1.25rem"
                    :class="{
                      'sf-header__icon is-active': activeIcon === 'wishlist',
                    }"
                  />
                </SfButton>
                <SfButton
                  :class="{ 'display-none': !cartIcon }"
                  class="sf-button--pure sf-header__action"
                  data-testid="cartIcon"
                  aria-label="Cart"
                  @click="$emit('click:cart')"
                >
                  <SfIcon
                    class="sf-header__icon"
                    :icon="cartIcon"
                    size="1.25rem"
                    :class="{
                      'sf-header__icon is-active': activeIcon === 'cart',
                    }"
                  />
                </SfButton>
              </div>
            </slot>
          </div>
          <nav
            class="sf-header__navigation"
            :class="{ 'is-visible': isNavVisible }"
          >
            <slot name="navigation"></slot>
          </nav>
          <slot name="topMenu" v-if="!isCheckoutPage"></slot>
          <div class="overlay hidden" @click="closeFilters" />
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';
import Vue from 'vue';
import {
  SfImage,
  SfSearchBar,
  SfButton,
  SfIcon,
  SfLink,
  SfHeaderNavigationItem,
  SfHeaderNavigation,
} from '@storefront-ui/vue';
import { isClient } from '~/helpers/check-environment';
import { BackCheckoutIcon, HamburgerIcon } from '~/components/General/Icons';
import { useTranslation } from '~/composables';

Vue.component('SfHeaderNavigation', SfHeaderNavigation);
Vue.component('SfHeaderNavigationItem', SfHeaderNavigationItem);

export default {
  name: 'SfHeader',
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    SfIcon,
    SfLink,
    BackCheckoutIcon,
    HamburgerIcon,
  },
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: [Number, String],
      default: 35,
    },
    logoWidth: {
      type: [Number, String],
      default: 34,
    },
    title: {
      type: String,
      default: '',
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: 'empty_cart',
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: 'profile',
    },
    activeIcon: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'account', 'wishlist', 'cart'].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: 'Search for items',
    },
    searchValue: {
      type: String,
      default: '',
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: '0',
    },
    cartItemsQty: {
      type: [String, Number],
      default: '0',
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: [],
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },
  computed: {
    cartHasProducts() {
      return Number.parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return Number.parseInt(this.wishlistItemsQty, 10) > 0;
    },
    isCheckoutPage() {
      return this.$route.path.includes('checkout');
    },
  },
  watch: {
    scrollDirection: {
      handler() {
        if (!isClient) return;
        window.cancelAnimationFrame(this.animationLong);
        this.animationLong = null;
        this.animationStart = null;
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
      },
    },
    isSticky: {
      handler(isSticky) {
        if (!isClient) return;
        this.sticky = isSticky;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isSticky) {
      window.addEventListener('scroll', this.scrollHandler, { passive: true });
    }
  },
  beforeUnmount() {
    if (this.isSticky) {
      window.removeEventListener('scroll', this.scrollHandler, {
        passive: true,
      });
    }
  },
  methods: {
    animationHandler(timestamp) {
      if (!this.animationStart) this.animationStart = timestamp;
      const progress = timestamp - this.animationStart;
      if (progress < this.animationDuration) {
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
        return;
      }
      this.hidden = this.scrollDirection === 'down';
    },
    scrollHandler() {
      if (!isClient) return;
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (
        !!this.refs &&
        currentScrollPosition >= this.$refs.header.offsetHeight
      ) {
        this.scrollDirection =
          currentScrollPosition < this.lastScrollPosition ? 'up' : 'down';
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  setup() {
    const addFilters = () => {
      const bodyEle = document.querySelectorAll('html');
      bodyEle.length > 0 && bodyEle[0].classList.add('hamburger-open');
    };
    const mouseClick = ref();
    const removeFilters = () => {
      const bodyEle = document.querySelectorAll('html');
      bodyEle.length > 0 && bodyEle[0].classList.remove('hamburger-open');
    };
    const openFilters = () => {
      addFilters();
    };
    const closeFilters = () => {
      removeFilters();
      mouseClick.value = false;
    };
    const { $gt } = useTranslation('translations');

    return {
      ref,
      openFilters,
      addFilters,
      closeFilters,
      removeFilters,
      $gt,
    };
  },
};
</script>

<style lang="scss">
.sf-header {
  position: static;
  background: var(--c-yellow);
  .sf-header-navigation-item {
    position: var(--header-navigation-item-position, relative);
    display: flex;
    flex: var(--header-navigation-item-flex, 0);
  }
  .menu_icon {
    display: var(--hidden);
  }
  &__header {
    padding: 0;
    justify-content: space-between;
    max-width: var(--w-full);
    width: 100%;
  }
  &__logo {
    flex: 0 0 var(--header-logo-width, auto);
    width: var(--w-auto);
    height: var(--h-auto);
    padding: 0.938rem 0;
    min-width: 14.375rem;
    margin-top: 0.125rem;
    @include to-tablet-1500 {
      min-width: 10rem;
      padding: 0.313rem 0;
      margin: 0;
    }
    svg,
    img {
      max-width: 11.875rem;
      @include to-tablet-1500 {
        max-width: 8.125rem;
      }
      @include to-mobile-max {
        max-height: 3rem;
      }
    }
  }
  &__actions {
    flex: none;
    display: var(--flex);
    flex-wrap: var(--flex-wrap);
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.438rem;
    @include to-tablet-1500 {
      padding: 0.625rem 0;
    }
  }
  &__navigation {
    margin: 0;
    display: var(--block);
  }
  &__search {
    --search-bar-display: none;
    margin: 0;
    border: 0;
    max-width: 68%;
    flex: none;
    input {
      color: var(--c-black);
      font-size: var(--font-size-16px);
    }
    .sf-input__wrapper {
      width: var(--w-full);
    }
    @include for-desktop {
      --search-bar-display: flex;
      --header-search-flex: 0 1 19.875rem;
    }
  }
  &__icons {
    display: var(--flex);
    margin: 0;
    .sf-button.sf-button--pure {
      margin: 0;
      min-height: 3.5rem;
      border-right: solid 0.063rem var(--c-white);
      padding: 0.625rem 1.563rem;
      @include to-tablet-1500 {
        min-height: 3rem;
        padding: 0.313rem 0.938rem;
      }
      span {
        position: var(--absolute);
        opacity: 0;
      }
      svg {
        height: 1.7rem;
        width: var(--w-auto);
      }
      &:last-child {
        border: 0;
        padding-right: 0;
      }
    }
  }
  &--has-mobile-navigation {
    @include for-mobile {
      .sf-header__navigation {
        display: var(--flex);
        flex-direction: column;
      }
    }
  }
  .sf-header__aside {
    display: var(--hidden);
  }
  .heder-right {
    width: var(--w-full);
    align-items: var(--align-items);
    margin-top: auto;
    max-width: 94.625rem;
    .sf-header-navigation-item__item {
      @include to-tablet-1500 {
        flex-wrap: var(--flex-wrap);
      }
      .sf-header-navigation-item__link {
        flex: none;
        font-size: var(--font-size-16);
        line-height: var(--line-height-22);
        text-transform: none;
        color: var(--c-dark-grey);
        margin: 0 3rem 0 0;
        padding: 0.625rem 0;
        border: 0;
        outline: none !important;
        &.active {
          color: var(--c-black);
          text-shadow: 0 0 1px var(--c-black);
        }
        @include to-tablet-1500 {
          margin: 0 1.25rem 0 0;
        }
        .sf-icon {
          display: var(--hidden);
        }
      }
    }
    .header-navigation-right {
      display: var(--flex);
      flex-wrap: var(--flex-wrap);
      a {
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
        line-height: var(--line-height-22);
        text-transform: none;
        color: var(--c-dark-grey);
        padding: 0.625rem 0.75rem;
        border-right: solid 0.063rem var(--c-white);
        display: var(--flex);
        align-items: var(--align-items);
        &.active {
          text-shadow: 0 0 0px var(--c-black);
        }
        svg,
        img {
          margin-right: 0.313rem;
        }
        &:last-child {
          border: 0;
          padding-right: 0;
        }
      }
      ul {
        padding: 0 !important;
        li {
          a,
          label {
            font-family: var(--lato-regular);
            font-size: var(--font-size-13);
            line-height: var(--line-height-16);
            text-transform: none;
            color: var(--c-dark-grey);
            padding: 1rem 0;
            border-right: solid 0.063rem var(--c-white);
            display: var(--flex);
            align-items: var(--align-items);
            width: var(--w-full);
            &.active {
              text-shadow: 0 0 0px var(--c-black);
            }
            svg,
            img {
              margin-left: auto;
            }
            &:last-child {
              border: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
    .header-navigation {
      display: var(--flex);
      justify-content: space-between;
      border-top: solid 1px var(--c-white);
      margin-right: -2.813rem;
      padding-right: 2.813rem;
      &.mobile-only {
        display: var(--hidden);
      }
      @include to-tablet-1500 {
        margin-right: -1rem;
        padding-right: 1rem;
      }
      @include to-tablet-1200 {
        margin-right: 0;
        padding-right: 0;
        border-top: 0;
      }
      @include to-mobile-max {
        border-top: solid 1px var(--c-dark-grey);
      }
    }
    &__title {
      @include to-tablet-1200 {
        display: none;
      }
      text-align: right;
      margin: 0.3125rem 0;
    }
  }
}
// Mobile menu sidebar css  ///
@include to-tablet-1200 {
  .hamburger-open {
    overflow: hidden;
    #__layout {
      .sf-header__navigation {
        display: var(--block);
        background: var(--c-white);
        left: var(--left-0);
        top: 5.75rem;
        height: var(--h-full);
        width: 32%;
        z-index: 5;
        min-width: 22.5rem;
        border-top: solid 1px var(--c-black);
        @include to-tablet-1200 {
          border: 0;
        }
        @include to-mobile-max {
          top: 7.525rem;
        }
        @include for-mobile {
          min-width: var(--w-full);
          width: var(--w-full);
        }
      }
      .heder-right {
        .desktop-only {
          display: var(--hidden);
        }
        .mobile-only {
          display: var(--block);
        }
        .overlay {
          display: var(--block);
          height: var(--h-full);
          width: var(--w-full);
          position: var(--fixed);
          left: var(--left-0);
          top: 5.75rem;
          background: var(--c-black);
          z-index: 2;
          opacity: 0.7;
          @include to-mobile-max {
            top: 7.525rem;
          }
        }
      }
    }
  }
  #__layout {
    .heder-right {
      display: var(--flex);
      border-top: solid 0.063rem var(--c-black);
      border-bottom: solid 0.063rem var(--c-black);
      .header-sidebar {
        .sf-header-navigation-item__item {
          display: var(--block);
          height: calc(100vh - 5.75rem);
          overflow: auto;
          position: var(--relative);
          padding-top: 3.125rem;
          > div:first-child {
            padding: 0;
            text-align: left;
            text-transform: none;
            border: 0;
            margin: 0;
          }
          ul {
            padding: 1.875rem 0.938rem;
            margin: 0;
            li {
              display: var(--flex);
              align-items: var(--align-items);
              justify-content: space-between;
              .go-arrow {
                margin-left: auto;
                padding: 0 15px 0 25px;
                margin-right: -14px;
                min-height: 44px;
                display: var(--flex);
                align-items: var(--align-items);
                justify-content: center;
              }
            }
          }
          .level {
            ul {
              padding-bottom: 1.25rem;
              li {
                text-transform: none;
                color: var(--c-dark-grey);
                font-size: var(--font-size-12);
                font-family: var(--lato-Regular);
                &.title {
                  label {
                    font-family: var(--lato-semibold);
                  }
                }
                a {
                  color: var(--c-dark-grey);
                  text-transform: none;
                  padding: 0.938rem 0;
                  display: var(--block);
                }
              }
            }
          }
          .level-one {
            ul {
              padding-bottom: 1.25rem;
              li {
                text-transform: none;
                color: var(--c-dark-grey);
                font-size: var(--font-size-24);
                font-family: var(--lato-medium);
                a {
                  color: var(--c-dark-grey);
                  text-transform: none;
                  padding: 0.75rem 0;
                  position: var(--relative);
                  align-items: var(--align-items);
                  width: var(--w-full);
                }
              }
            }
          }
        }
        .menu-back-row {
          background: var(--c-yellow);
          position: var(--absolute);
          width: var(--w-full);
          left: var(--left-0);
          top: var(--top-0);
          display: var(--flex);
          align-items: var(--align-items);
          height: 3.125rem;
          border-bottom: solid 1px var(--c-black);
          font-size: var(--font-size-13);
          padding: 0 0.813rem;
          .close-menu {
            margin-left: auto;
            cursor: var(--cursor-pointer);
          }
          .back {
            display: var(--flex);
            align-items: var(--align-items);
            padding: 0.938rem 0;
            svg {
              margin-right: 0.875rem;
            }
          }
        }
        .header-navigation-right {
          display: var(--block);
          border-top: solid 1px var(--c-black);
          padding: 0.938rem 0 3.75rem 0;
          position: var(--relative);
          margin: 0 0.938rem;
          a {
            padding: 1.25rem 0;
          }
        }
      }
    }
    .sf-header {
      .container {
        padding: 0;
      }
    }
    .menu_icon {
      display: var(--flex);
      cursor: pointer;
      min-width: 3.5rem;
      width: 3.5rem;
      justify-content: center;
      align-items: var(--align-items);
      border-right: solid 1px var(--c-black);
      height: 3rem;
    }
    .sf-header__actions {
      padding: 0;
      width: calc(100% - 3.5rem);
    }
    .sf-header__logo {
      min-height: auto;
      display: var(--flex);
      padding: 0.625rem 0 0.625rem 0.938rem;
      max-width: 6.25rem;
      svg {
        height: auto;
      }
    }
    .sf-header__header {
      display: var(--block);
      .title {
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
        left: 0.625rem;
        text-align: center;
      }
      .sf-header__search {
        width: var(--w-full);
        max-width: var(--w-full);
      }
      .sf-header__icons {
        position: var(--absolute);
        right: 0.938rem;
        top: 1.438rem;
      }
    }
    .sf-header__navigation {
      display: var(--hidden);
      position: var(--fixed);
      animation: animateleft 0.4s;
    }
    @keyframes animateleft {
      from {
        left: -22.5rem;
        opacity: 0;
      }
      to {
        left: 0;
        opacity: 1;
      }
    }
  }
}
@include to-mobile-max {
  #__layout {
    .sf-header__logo {
      padding: 3.5rem 0 0.938rem 0.938rem;
      svg {
        max-width: 5rem;
      }
    }
    .sf-header__header {
      .sf-header__icons {
        top: 3.5rem;
      }
    }
  }
}
.checkout-header {
  .sf-header__header {
    position: relative;
    @media (max-width: 1023px) {
      justify-content: center;
    }
    .back {
      position: absolute;
      left: 20px;
    }
  }
}
@media (max-width: 415px) {
  #__layout {
    .sf-header__header {
      .title {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 395px) {
  #__layout {
    .sf-header__header {
      .title {
        font-size: 13px;
      }
    }
  }
}
</style>
